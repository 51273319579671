@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.gallery-wrap {
  display: flex;
  width: 100%;
  overflow: hidden;
  gap: 5px;
}

.gallery-item {
  flex: 1;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e9e9e9;

  height: 100%;
  max-height: 400px;
}

.gallery-item:hover {
  opacity: 0.7;
}

// Custom
.gallery-img {
  height: 100%;
  min-height: 400px;
  width: 100%;

  object-fit: cover;
  object-position: center;
}

/* ToggleButton.css */

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  position: relative;
  cursor: pointer;
}

.toggle-input {
  display: none;
}

.toggle-text {
  font-size: 16px;
  margin-left: 8px;
}

/* Style for the toggle switch itself */
.toggle-input + .toggle-text::before {
  content: '';
}

.toggle-input:checked + .toggle-text::before {
  content: '';
}

/* Style for the toggle switch background */
.toggle-input + .toggle-text::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: 70px;
  height: 26px;
  background-color: #ddd;
  border-radius: 14px;
  transition: background-color 0.3s ease-in-out;
}

.toggle-input:checked + .toggle-text::after {
  background-color: #4caf50; /* Change this to your desired checked color */
}

// Logos picker
.logos-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.logos-item {
  background: transparent;
  border: 1px solid rgb(164, 164, 3);
  padding: 6px;
  border-radius: 16px;
}

.logos-item img {
  width: 100px;
  height: auto;
}

.logos-item.active {
  background: rgba(2, 143, 26, 0.27);
  border: 1px solid rgb(2, 143, 25, 0.8);
}

.product-logo-list {
  max-width: 70px;
  height: auto;
}

// time-picker-custom
.time-picker-custom {
  display: flex;
  flex-direction: column;
}

.time-picker-selects {
  display: flex;
  align-items: center;
  gap: 4px;
}

.time-picker-selects select {
  background: transparent;
  border: 1px solid #404656;
  color: #b4b7bd;
  padding: 9px;
  border-radius: 0.357rem;
}

.time-picker-selects select:focus {
  outline: none;
}

.blog-post-image-container {
  position: relative;
}

.blog-post-image-all {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.blog-post-image-edit-btn {
  display: flex;
  background: transparent;
  border: none;
  color: #fff;
  position: absolute;
  right: 8px;
  top: 8px;
}

// youtube-input-field"
.youtube-input-field {
  display: flex;
  border: 1px solid #404656;
  border-radius: 8px;
  overflow: hidden;

  margin: 8px auto;
  p {
    background: #404656;
    height: 100%;
    margin: 0;
    text-align: right;
    width: 100%;
    padding: 8px;
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    color: #b4b7bd;
    padding: 8px;

    &:focus {
      outline: none;
    }
  }
}

.ql-formats button svg {
  color: red;
}
